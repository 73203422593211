@media screen and (max-width: 1400px) {
    .header-left-side > .button-bulleted-list, .header-left-side > .button-numbered-list, .header-left-side > .button-check-list-item {
        display: none !important;
    }
    #dropdown_lists {display: block;}
}
@media screen and (max-width: 1350px) {
    .header-left-side > .button-left, .header-left-side > .button-center, .header-left-side > .button-right, .header-left-side > .button-justify {
        display: none !important;
    }
    #dropdown_align {display: block;}
}
@media screen and (max-width: 1260px) {
    .header-left-side > .button-image, .header-left-side > .button-link {
        display: none;
    }
    #dropdown_lists {display: none;}
    #dropdown_more {display: block; right: 0;}
    #dropdown_align {display: none;}
    /*#dropdown_lists .header-dropdown-menu-content {right: 0;}*/
    .header-left-side .ms2, .header-left-side .ms3 {display: none;}
    .header-left-side .ms1 {flex-grow: 1;}
}
/*@media screen and (max-width: 1195px) {
    .main-container {--sidebarWidth: 255px !important;}
}*/
@media screen and (max-width: 1060px) {
    .header-right-side > .button-settings, .header-right-side > .button-save, .header-right-side > .button-logout, .header-right-side > .button-item {
        display: none;
    }
    #dropdown_general {display: block;}
    #dropdown_lists .header-dropdown-menu-content {right: 0;}
}
/*@media screen and (max-width: 920px) {
    .main-container {--sidebarWidth: 250px !important;}
}*/
@media screen and (max-width: 890px) {
    #dropdown_more .header-dropdown-menu-content {right: 0;}
    .header-left-side .ms0, .header-left-side .ms1 {display: none;}
}
/*@media screen and (max-width: 810px) {
    .header-menu {
        position: fixed;
        right: 20px;
    }
    .tab-mobile-visible .textfield {
        position: fixed;
        left: 20px;
        top: 97px;
        /*max-height: calc(100vh - 116px);* /
        height: calc(100vh - 116px);
        overflow: auto;
        z-index: -1;
    }
    .tab-mobile-hidden .textfield {
        display: none;
    }
    .tab-mobile-visible .sidebar_tabs {
        display: none;
    }
    body, #root, .tab-mobile-visible aside, .tab-mobile-visible.main-container {
        /*height: 90px;* /
        z-index: -2;
        position: relative;
    }
    .main-container {
        --sidebarWidth: calc(100% - 10px) !important;
    }
    .item {
        background-color: white;
        color: black;
        width: calc(100vw - 40px)
    }
    .item::after {
        opacity: 1 !important;
    }
    .tab-mobile-hidden .header-history, .tab-mobile-hidden .header-left-side {
        display: none;
    }
    .header-left-side {
        display: none;
    }
    .header-right-side {
        margin-right: 0;
    }
    .close_textfield {
        display: block;
    }
    .item_dragging {
        width: calc(100vw - 40px);
    }
    .tab-mobile-hidden #dropdown_general {
        display: none;
    }
    .tab-mobile-hidden .header-right-side > .button-settings, .tab-mobile-hidden .header-right-side > .button-save, .tab-mobile-hidden .header-right-side > .button-logout, .tab-mobile-hidden .header-right-side > .button-item {
        display: block;
    }
    .settings_sidebar_sorting {
        display: none;
    }
    .item:not(.selected) .item_category_indicator {
        opacity: 1;
    }
}
/*@media screen and (max-width: 540px) {
    .tab-mobile-visible .header-title-text {
        display: none;
    }
}*/
/*@media screen and (max-width: 430px) {
    .header-left-side > .button-bold, .header-left-side > .button-italic, .header-left-side > .button-underline {
        display: none;
    }
    #dropdown_more {display: none;}
    #dropdown_all_text_styles {display: block;}
}*/
@media screen and (max-width: 810px) {

    body {overflow-y: visible;}
    .main-container {
        /*flex-direction: column;*/
        display: block;
        height: auto;
        min-height: 100vh;
    }
    main {
        height: calc(100vh - 81px);
    }

    .tab-mobile-visible aside {
        display: none;
    }

    .mobile_top_menu {
        display: flex;
    }
    .side_menu {
        padding: 0;
        height: auto;
        width: auto;
    }
    .side_menu_content {display: none;}
    .side_menu_icon_container {
        margin-left: 13px;
        /*position: fixed;*/
        height: auto;
        margin-top: 30px;
        margin-bottom: 15px;
    }
    .side_menu_mobile_save {
        display: block;
    }
    .main-container:not(.tab-mobile-visible) .mobile_menu {
        display: flex;
    }

    .homescreen {
        padding-bottom: 80px;
        /*margin-top: 0;
        /*overflow: visible;*/
        
    }
    .homescreen_header {
        display: none;
    }
    .homescreen_category_container {
        display: block;    
        width: 100vw;
    }
    .homescreen_category {
        margin-right: 20px;
    }
    .homescreen_category:not(:first-of-type) {
        margin: 0 20px 0 0;
    }
    .category_title {width: auto;}

    .item {
        width: auto;
    }

    .homescreen_category_title_button {
        padding: 10px;
    }

    .header-menu {
        margin-left: 10px;
    }
    .header-menu .box {
        margin-top: 0;
        margin-bottom: 10px;
        padding-top: 5px;
    }
    #dropdown_more .button-bold, #dropdown_more .button-italic, #dropdown_more .button-underline {
        display: flex;
    }
    .header-left-side > .button-bold, .header-left-side > .button-italic, .header-left-side > .button-underline {
        display: none;
    }
    .header-left-side .ms0 {display: block;}

    .textfield {
        margin-left: 20px;
    }
    .close_textfield {
        display: block;
    }
    
    .calendar_current_month {display: none}
    .calendar_container {
        padding: 0;
        height: calc(100vh - var(--headerHeight) - 70px);
        width: 100vw;
    }
    .calendar_content {
        height: calc(100% - 80px);
    }
    .calendar_day_name {
        padding: 0;
        text-align: center;
    }
    .calendar_title {
        margin-top: 8px;
        margin-left: 13px;
        margin-right: 13px;
        margin-bottom: 0px;
    }
    .calendar_title_text {
        font-size: 16px;
        margin-left: 10px;
    }
}
