@font-face {
    font-family: Montserrat;
    src: url('../font/montserrat/Montserrat-Light.ttf');
    font-weight: lighter;
}
@font-face {
    font-family: Montserrat_Font;
    src: url('../font/montserrat/Montserrat-Light.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: Montserrat;
    src: url('../font/montserrat/Montserrat-Italic.ttf');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: Montserrat_Font;
    src: url('../font/montserrat/Montserrat-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: Montserrat_Font;
    src: url('../font/montserrat/Montserrat-Bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: Montserrat;
    src: url('../font/montserrat/Montserrat-Light.ttf');
    font-weight: light;
}