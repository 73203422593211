* {
    --secondaryBGColor: rgba(252, 254, 255, 0.3);
    --standardShadow: 0px 0px 20px rgba(0,0,0,0.06);
    --overlayShadow: 0px 0px 20px rgba(0,0,0,0.15);
    --greyBG: rgba(0, 0, 0, .1);
    --headerHeight: 97px;
    --standardBorderRadius: 12px;
    /*--sideMenuWidth: 200px;*/

    font-family: Montserrat_Font;
    font-weight: normal;
    box-sizing: border-box;
}

body {
    /*--mainColor: 89, 131, 202;
    background-color: rgba(var(--mainColor), 0.1);*/
    overflow: hidden;
    background-color: rgb(var(--mainColor));
}

b, strong, strong * {
    font-weight: bold;
}
.unselectable {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
.no-transition {
    transition: none !important;
}

.login_bg {
    background-color: rgba(var(--mainColor), 0.1);
    position: fixed;
    width: 100vw;
    height: 100vh;
}

.main-container {
    background-color: rgba(var(--mainColor), 0.1);
    display: flex;
    height: 100vh;
}
aside:not(.no_items) {
    flex-basis: var(--sidebarWidth);
}
main {
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
}
aside:not(+ .main) {
    flex-basis: auto;
}

.header-title {
    /*font-size: 35px;*/
    /*margin-top: 28px;
    margin-bottom: 30px;*/
    
    /*font-weight: lighter;*/
    height: var(--headerHeight); /*height: 43px;*/
    display: flex;
}
/*.header-title-text {
    font-size: 22px;
    font-weight: bold;
    flex: 1 0 0;
    align-self: center;
}*/
/*.title-login {
    margin-left: 20px;
}*/

.box {
    padding: 15px 17px;
    border-radius: var(--standardBorderRadius);
    box-shadow: var(--standardShadow);
    margin: 20px;
}


/*
SIDE-MENU
*/
.side_menu {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0px 0px 20px 20px;
    position: relative;
    z-index: 1;
    width: var(--sidebarWidth);
}
.side_menu_icon_container {
    height: var(--headerHeight);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    font-size: 30px;
    position: relative;

    -webkit-app-region: drag;
}
.side_menu_icon {
    width: 36px;
    margin: 0 7px 0 0;
    box-shadow: var(--standardShadow);
    border-radius: 9px;
}
.side_menu_icon_text {
    /*position: absolute;*/
    font-size: 22px;
    font-weight: bold;
    /*left: calc(100% + 20px);
    white-space: nowrap;*/
    margin-left: 10px;
}
.side_menu_content {
    flex: 100%;
    /*width: var(--sideMenuWidth);*/

    /*background-color: var(--secondaryBGColor);
    box-shadow: var(--standardShadow);
    border-radius: 10px;*/
}
.side_menu_content_inner {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.side_menu_category {
    display: flex;
    align-items: center;
    border-radius: 6px;
    padding: 10px;
}
.side_menu_category.active {
    background-color: white;
    box-shadow: var(--standardShadow);
}
.side_menu_color {
    height: 30px;
}

.side_menu_general, .side_menu_add_items {
    /*background-color: var(--secondaryBGColor);
    border-radius: 12px;
    box-shadow: var(--standardShadow);*/
    white-space: nowrap;
}

/*.side_menu_home {
    flex: 1;
}*/
.side_menu_general {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    /*justify-content: center;*/
    margin-top: 20px;
    margin-bottom: 20px;

    max-height: calc(100vh - var(--headerHeight) - 310px);
    overflow: auto;
}
.side_menu_categories {
    margin: auto 0;
}
/*.side_menu_general_top {
    flex: 1 0 0;
}
.side_menu_margin_top {
    margin-top: 20px;
}*/

/*.side_menu_section {
    /*background-color: var(--secondaryBGColor);
    box-shadow: var(--standardShadow);
    border-radius: 10px;* /
}*/

.side_menu_item {
    font-size: 17px;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: var(--standardBorderRadius);
    --categoryColor: rgb(var(--mainColor));
}
.side_menu_item:hover {
    background-color: var(--greyBG);
}
.demo .side_menu_item.demo_disabled:hover {
    background-color: transparent;
}
/*.side_menu_item:active {
    background-color: rgba(0, 0, 0, .2);
}*/
.side_menu_item.active {
    background-color: white;
    box-shadow: var(--standardShadow);
    position: relative;
    /*margin-left: -30px;
    padding-left: 40px;*/
    /*color: rgb(var(--mainColor));*/
}
.demo .side_menu_item.demo_disabled .side_menu_item_icon {
    opacity: 0.2;
}
.demo .side_menu_item.demo_disabled .side_menu_item_text {
    opacity: 0.3;
}
/*.side_menu_item.active::after {
    content: '';
    background-color: var(--categoryColor);
    position: absolute;
    width: 4px;
    height: 15px;
    left: -13px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 4px;
}*/
/*.side_menu_item_category {
    margin-left: 15px;
}*/

.side_menu_item_icon {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    /*margin-right: 10px;*/
    font-weight: 500;
}
.category_color /*.side_menu_item_icon_category*/ {
    width: 14px;
    height: 14px;
    border-radius: 3px;
    /*border: 2px solid var(--categoryColor);*/
    background-color: var(--categoryColor);
    flex-shrink: 0;
}
/*.side_menu_item.active .side_menu_item_icon_category {
    background-color: var(--categoryColor);
}*/
.side_menu_item_text {
    /*max-width: 0;
    transition: 1s max-width ease;*/
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    margin-left: 6px;
    margin-right: 15px;
    text-overflow: ellipsis;
}
/*.side_menu:hover .side_menu_item_text {
    max-width: 200px;
}*/

.side_menu_category_heading {
    margin-bottom: 10px; 
    margin-left: 17px;
    font-size: 14px;
}

/*.side_menu_item*/.unsaved_changes {
    position: relative;
}
/*.side_menu_item*/.unsaved_changes::after {
    content: '';
    position: absolute;
    top: 11px;
    right: 11px;
    background-color: rgba(var(--mainColor), 1);
    width: 7px;
    height: 7px;
    border-radius: 50%;
}

.demo .demo_disabled.unsaved_changes::after {
    display: none;
}

/*.side_menu_mobile_save {
    font-size: 20px;
    position: fixed;
    top: 40px;
    right: 40px;
    display: none;
}*/

/*
MOBILEMENU
*/
.mobile_menu {
    position: fixed;
    background-color: white;
    box-shadow: var(--overlayShadow);
    bottom: -2px;
    width: 100%;
    justify-content: space-evenly;
    padding: 16px 0px 15px;
    display: none;
}
.mobile_menu_item {
    font-size: 13px;
    width: 70px;
    text-align: center;
}
.mobile_menu_item svg {
    font-size: 20px;
    display: block;
    margin: auto;
    margin-bottom: 7px;
}
.mobile_menu_item.unsaved_changes::after {
    top: -6px;
    right: 19px;
}

.mobile_top_menu {
    position: absolute;
    top: -6px;
    right: 12px;
    font-size: 20px;
    display: none;
}
.mobile_top_menu_item {
    padding: 15px 15px 13px;
    line-height: 1;
}
.mobile_top_menu_item.active {
    background-color: white;
    border-radius: var(--standardBorderRadius);
    box-shadow: var(--standardShadow);
}

/*
HOMESCREEN
*/
.homescreen {
    /*margin-left: 15px;*/
    /*margin-top: var(--headerHeight)/*calc(var(--headerHeight) + 11px);*/
    flex: 1;
    /*flex-wrap: wrap;*/
    /*display: grid;
    grid-gap: 11px;
    grid-template-columns: repeat(auto-fill, calc(var(--sidebarWidth) - 20px));
    grid-auto-rows: 20px;*/
    /*margin-left: -60px;
    padding-left: 80px;*/
}

.homescreen_header {
    height: var(--headerHeight);
    flex: 100%;
    display: flex;
    align-items: center;

    -webkit-app-region: drag;
}
.homescreen_search {
    margin-left: 20px;
    display: flex;
    border-radius: var(--standardBorderRadius);
    position: relative;
}
.homescreen_search:focus-within {
    background-color: white;
    box-shadow: var(--standardShadow);
}
.homescreen_search_icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
    line-height: 1;
}
.homescreen_search_input {
    background-color: transparent;
    border: 0 none;
    outline: 0 none;
    font-size: 14px;
    padding: 16px 16px 16px 48px;
    border-radius: var(--standardBorderRadius);
}

.homescreen_category_container {
    display: flex;
    align-content: baseline;
    overflow-y: auto;
    height: calc(100vh - var(--headerHeight));
    padding-left: 20px;
    width: calc(100vw - var(--sidebarWidth));
}
.homescreen_category {
    margin-right: 20px;
}
/*.homescreen_category:not(:first-of-type) {
    /*flex: calc(var(--sidebarWidth) - 40px);
    flex-grow: 0;
    flex-shrink: 0;* /
    margin-left: 20px;
}*/
.homescreen .box {
    margin-left: 0;
    margin-right: 0;
    background-color: white;
}
.homescreen_category_empty {
    margin-top: 20px;
}
.homescreen_category_items_hidden {margin-top: -8px;}
.homescreen_category_items_hidden_true {display: none;}

.category_title {
    /*margin-bottom: 33.5px;*/
    display: flex;
    align-items: center;
    margin-top: 11px;
    width: calc((100vw - var(--sidebarWidth) - 100px) / 4);
}
.category_title_text {
    flex: 0;
    white-space: nowrap;
    font-weight: bold;
    margin-right: 7px;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
}
.category_title_button {
    padding: 5px;
    display: flex;
    opacity: .5;
    border-radius: 5px;
}
.category_title_button:hover {
    background-color: var(--greyBG);
    opacity: .7;
}
.category_title_spacer {
    flex-grow: 1;
    flex-shrink: 1;
}
.category_title_color {
    width: 18px;
    height: 18px;
    border-radius: 5px;
    margin-right: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
}
aside .category_title {
    margin-left: 20px;
    margin-right: 12px;
}

/*
ITEM-SIDEBAR
*/
/*.sidebar_tabs  {    
    overflow-y: scroll;
    height: calc(100vh - 120px);
}*/
.sidebar_category_container {
    overflow-y: scroll;
    height: calc(100vh - var(--headerHeight));
    margin-right: -80px;
    padding-right: 80px;
}
/*.sidebar_category_title {
    margin: 14px 20px 37px;
    font-weight: bold;
    display: flex;
}*/
.sidebar_category_container nav {
    margin-top: -8px;
}
.category_view_items_hidden {
    display: none;
}

* {
    /* Works on Firefox */
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    transition: scrollbar-color .2s;
}

*:hover {
    scrollbar-color: rgba(146, 147, 156, 0.54) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    /*border: 3px solid transparent;*/
    transition: background-color .2s;
}
*:hover::-webkit-scrollbar-thumb {
    background-color: rgba(146, 147, 156, 0.54);
}
/*.sidebar-text {
    margin-left: 20px;
}*/
/*.sidebar_tabs .day:first-child .sidebar-text:first-child {
    margin-top: 10px;
}*/
/*.sidebar_tabs .day:first-child .drag_position_indicator:first-child + .item {
    margin-top: 0;
}*/
/*.sidebar_tab_color_input  .item::after*/ .item_category_indicator {
    content: '';
    background-color: var(--categoryColor);
    /*background-color: transparent;
    border: 0;*/
    height: calc(100% - 20px);
    width: 5px;
    border-radius: 2.5px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px;
    /*padding: 0;*/
    /*transition: width .1s;*/
    /*cursor: pointer;*/
}
/*.sidebar_tab_color_input:hover .item_category_indicator:hover {
    width: 10px;
    border-radius: 5px;
}*/
/*.item:not(.selected) .sidebar_tab_color_input .item:not(.selected)::after*/ .item:not(.selected) .item_category_indicator {
    opacity: 0;
}
.sidebar_tab_options {
    position: absolute;
    top: 14px;
    right: 25px;
    display: flex;
}
.sidebar_tab_options > div {
    background-color: transparent;
    color: transparent;
    /*opacity: 0;*/
    transition: color .2s;
    font-size: 19px;

    padding: 6px 0px 0px;
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 7px;
    margin-left: 0px;
}
/*.item_option_mobile {
    display: none;
}*/
/*.item:hover .sidebar_tab_options div, .item-done .sidebar_tab_check {
    color: rgb(81, 81, 81);
}*/
.sidebar_tab_options > div:not(.item_dropdown_menu):hover {
    background-color: var(--greyBG);
}
.sidebar_tab_options div.sidebar_tab_check {
    /*position: absolute;
    top: 14px;
    right: 27px;*/
    /*max-width: 0;*/
    /*overflow: hidden;
    transition: max-width .2s;*/
    font-size: 22px;
    padding: 5px 0px 0px;

    /*background-color: var(--categoryColor);*/

}
/*.sidebar_tab_check:hover {
    background-color: var(--greyBG);
}*/
.item:hover .sidebar_tab_options div {
    color: #343434;
}
.item-done .sidebar_tab_check {
    color: #6c6c6c;
}
/*.sidebar_sort_items {
    margin-right: 20px;
    font-size: 18px;
    position: relative;
    width: 18px;
}
.sidebar_sort_items svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}*/

.item {
    background-color: var(--secondaryBGColor);
    color: #555555;
    transition: background-color .2s, opacity .2s;
    cursor: default;
    position: relative;
    padding: 0;
    width: calc((100vw - var(--sidebarWidth) - 100px) / 4);
    margin-right: 12px;
}
.item_dragging {
    position: absolute;
    z-index: 10;
}
/*.item-done, .item:hover {
    padding-left: 56px;
}*/
.item.selected, .item:hover {
    background-color: white;
    color: black;
}
.item_text {
    padding: 20px 22px 20px 22px;
}
.item_preview_content {
    /*width: var(--sidebarWidth);*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/*.item p {margin: 0;}
/*.item p + p {margin: 10px 0 0;}*/
.sidebar_content_preview {
    line-height: 1.5;
    max-height: 24px/*49px*/;
    overflow: hidden;
    margin-bottom: -3px;
    /*text-overflow: ellipsis;*/
}
.sidebar_content_preview * {
    font-size: 16px !important;
}
.item_preview_title + .sidebar_content_preview {
    margin-top: 7px;
}
.item_preview_title {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /*No linebreak when insufficient width*/
    margin: 0;
}
.item_preview_date, .item_preview_category {
    margin: 0;
    display: flex;
    align-items: center;
    line-height: 1;
    padding: 0px 0 10px;
}
.item_preview_category {align-items: unset;}
.item_preview_date_text, .item_preview_category_text {
    /*font-weight: 500;*/
    padding-left: 7px;

    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item-done .item_text > *:first-child {
    max-width: calc(100% - 40px);
}
.item:hover .item_text > *:first-child, .dropdown_visible .item_text > *:first-child {
    max-width: calc(100% - 107px);
}

.item_change_position {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 13px;
}
.item_move_button {
    line-height: 1;
    font-size: 30px;
    display: flex;
    align-items: center;
    position: relative;
}
.mobilemoveitem .item_change_position {display: block;}
.mobilemoveitem .item {
    padding-left: 30px;
}
.end_mobilemoveitem {display: none;}
.mobilemoveitem .end_mobilemoveitem {display: block;}
.mobilemoveitem .mobile_menu > .mobile_menu_item {display: none;}

.end_mobilemoveitem .mobile_menu_item {width: auto;}

.sidebar_content_preview strong, .sidebar_content_preview strong * {
    font-weight: normal;
}

.drag_position_indicator {
    height: 0;
    width: 0;
    transition: height .1s, width .5s;
}
.drag_position_indicator.active[data-group="sidebar"] {
    height: 100px;
}
.drag_position_indicator.active[data-group="homescreen"] {
    width: calc(var(--sidebarWidth) - 10px);
}
/*Fixes for dropdown menu for items*/
div.header-dropdown-menu.item_dropdown_menu button {
    border: 0 none;
    background-color: transparent;
    padding: 0;
    width: 100% !important;
    height: 100%;
    font-size: 17px;
    text-align: center;
    color: transparent;
    transition: color .1s;
    border-radius: 7px;
}
div.header-dropdown-menu.item_dropdown_menu button svg, .item svg {
    margin: auto;
}
.item:hover .item_dropdown_menu button, 
.item .item_dropdown_menu .header-dropdown-menu-content + button, 
.item_dropdown_menu.active ~ div, .item_dropdown_menu.active,
.item.mobile_selected .sidebar_tab_options > div, .item.mobile_selected .sidebar_tab_options button {
    color: black;
}
.item_dropdown_menu button:hover, 
.header-dropdown-menu-content + button, 
.header-dropdown-menu-content > div:not(.no_hover_effect):hover {
    background-color: var(--greyBG) !important;
}
.header-dropdown-menu-content > div {
    padding: 9px 7px;
    border-radius: 7px;
}
div .item_dropdown_menu {
    display: block;
    padding: 0;
}

/*
CALENDAR
*/
.calendar_container {
    /*margin-top: var(--headerHeight);*/
    padding: 15px 20px;
    width: calc(100vw - var(--sidebarWidth));
}
.calendar_title {
    margin-top: 10px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}
.calendar_title_arrow {
    align-self: center;
    line-height: 0.7;
    /*margin: 0 10px 0 0;*/
    padding: 10px;
    border-radius: 7px;
}
.calendar_title_arrow:hover {
    background-color: var(--greyBG);
}
.calendar_title_text {
    font-size: 22px;
    font-weight: bold;
    margin-left: 20px;
}
.calendar_title_spacer {flex: 1;}

.calendar_content {
    background-color: var(--secondaryBGColor);
    box-shadow: var(--standardShadow);
    border-radius: var(--standardBorderRadius);
    height: calc(100% - 136.5px);
}
.calendar_day_names {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 18px 0px 16px;
    font-size: 13px;
}
.calendar_day_name {/*padding-left: 17px*/text-align: center;}
.calendar_days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
    /*padding: 10px 10px 0;*/
    width: 100%;
    height: 100%;
}
.calendar_day {
    border: solid var(--greyBG);
    border-width: 0px 1px 1px 0px;
    padding: 10px;
    /*background-color: var(--secondaryBGColor);
    /*box-shadow: var(--standardShadow);
    border-radius: 7px;
    margin: 5px;*/
    overflow-y: scroll;
}
.calendar_day.current_month {
    background-color: white;
}
/*.calendar_day:nth-child(-n+7){border-top-width: 1px}*/
.calendar_day:nth-child(7n){border-right-width: 0px}
.calendar_day:nth-last-child(-n+7){border-bottom-width: 0px}

.calendar_day:nth-child(1){border-top-left-radius: var(--standardBorderRadius)}
.calendar_day:nth-child(7){border-top-right-radius: var(--standardBorderRadius)}
.calendar_day:nth-child(36){border-bottom-left-radius: var(--standardBorderRadius)}
.calendar_day:nth-child(42){border-bottom-right-radius: var(--standardBorderRadius)}

.calendar_day_title {
    font-size: 13px;
    padding: 7px;
    width: max-content;
    line-height: 0.6;
    border-radius: 5px;
    margin: auto;
}
.today .calendar_day_title {
    background-color: rgb(var(--mainColor));
    color: white;
}
.calendar_day:not(.current_month) .calendar_day_title {
    color: #00000072;
}

.calendar_entry {
    display: inline-flex;
    padding: 5px 8px;
    border-radius: 4px;
    cursor: default;
}
.calendar_entry:hover {
    box-shadow: var(--standardShadow);
    background-color: rgba(var(--mainColor), 0.1);
}
.calendar_entry_color {
    margin-right: 8px;
    margin-top: 3px;
}

.calendar_title_select {
    /*A reset of styles, including removing the default dropdown arrow*/
    appearance: none;
    /*Additional resets for further consistency*/
    background-color: transparent;
    border: none;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: 14px;
    cursor: inherit;
    line-height: inherit;
    padding: 16px;
    position: relative;
    z-index: 1;
}

.calendar_title_select_container {
    width: 100%;
    min-width: 15ch;
    max-width: 30ch;
    border-radius: var(--standardBorderRadius);
    font-size: 1.25rem;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

    position: relative;
    box-shadow: var(--standardShadow);
}

/*.calendar_title_select_container:after {
    content: '';
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid black;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}*/

.calendar_title_select_arrow {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    display: flex;
    font-size: 16px;
}

/*
TRASH
*/
.trash {
    padding-left: 20px;
    width: calc(100vw - var(--sidebarWidth) - 27px);
}
.trash_header {
    height: var(--headerHeight);
    display: flex;
    align-items: center;
}
.trash_title {
    font-size: 22px;
    font-weight: bold;
    flex: 1;
}
.trash_content {
    display: flex;
}
.trash_content_item {
    margin-left: 0;
    margin-top: 0;
    margin-right: 20px;
    background-color: white;
    /*width: calc((100vw - var(--sidebarWidth) - 100px) / 4);*/
    /*padding: 20px 22px;*/
    position: relative;
}
.trash_content_item:hover .item_text > *:first-child {
    max-width: calc(100% - 77px);
}
.empty_message {
    padding-top: 14px;
}

/*
HEADER
*/
.header-menu {
    display: flex;
}
.header-history, .header-left-side/*, .header-right-side*/ {
    margin-left: 0;
    margin-right: 0;
    /*background-color: var(--secondaryBGColor);*/
    box-shadow: none;
}
.header-history {
    display: flex;
}
.header-left-side {
    flex: 1 0 0;
    display: flex;
}
.header-left-side > * {
    flex-shrink: 0;
}
.header-left-side .menu-seperator {
    display: inline-block;
    width: 30px; /*30px*/
}
/*.header-right-side {
    display: flex;
    position: relative;
}*/
.header-menu .box {
    padding: 10px;
}
.header-dropdown-menu {
    position: relative;
    margin-right: 2px;
    display: none;
}
.header-dropdown-menu .header-dropdown-menu-icon {
    display: flex;
    align-items: center;
    width: 53px;
}
.header-dropdown-menu.no-arrow .header-dropdown-menu-icon {width: 37px}
.header-dropdown-menu.only_arrow .header-dropdown-menu-icon {width: auto}
.header-dropdown-menu .header-dropdown-menu-icon svg {
    flex-shrink: 0;
}
.header-dropdown-menu .header-dropdown-menu-icon .header-dropdown-menu-arrow {
    font-size: 15px;
}
.header-dropdown-menu.small_arrow .header-dropdown-menu-icon .header-dropdown-menu-arrow {
    font-size: 11px;
}
.header-dropdown-menu-content + .header-dropdown-menu-icon {
    background-color: var(--greyBG);
}
.header-dropdown-menu-content {
    position: absolute;
    background-color: white;
    top: 100%;
    border-radius: 10px;
    white-space: nowrap;
    padding: 7px 7px;
    z-index: 20;
    text-align: left;
    box-shadow: var(--overlayShadow);
}
/*.header-right-side > button .button_text,*/ .header-left-side > button .button_text {
    display: none;
}
.header-dropdown-menu.align-right .header-dropdown-menu-content {
    right: 0;
}
.header-menu .header-dropdown-menu-content button, .item_dd_entry {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0px 7px;
    margin-left: 0;
}
.header-menu .header-dropdown-menu-content button svg, .item_dd_entry svg {
    flex-shrink: 0;
    margin: 0;
    flex-basis: 22px;
}
.header-dropdown-menu-content .button_text {
    font-size: 14px;
    margin-left: 10px;
    padding-right: 10px;
}

.header-menu button {
    background-color: transparent;
    border: 0 none;
    font-size: 18px;
    width: 37px;
    height: 37px;
    border-radius: 7px;
    position: relative;
    display: block;
    color: #343434;
    padding: 0 4px;
}
.header-menu button::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    border-radius: 7px;
}
/*.header-menu*/.textfield_focus_true button:not(.inactive):hover::after, 
.history_button:not(.inactive):hover::after,
#dropdown_more .header-dropdown-menu-icon:hover::after {
    background-color: var(--greyBG);
}
nav.textfield_focus_true button.active:not(.dialog_title), button.always-active {
    background-color: rgba(var(--mainColor), 1);
    color: white;
}
.textfield_focus_true button.active:hover::after, button.always-active:hover::after {
    background-color: rgba(255, 255, 255, 0.2);
}
.textfield_focus_true button.active + button.active::before, button.always-active + button.active::before {
    content: '';
    background-color: rgba(var(--mainColor), 1);
    position: absolute;
    height: 100%;
    width: 14px;
    left: -7px;
    top: 0;
    z-index: 0;
}
.button-bold {font-weight: bold;}
.button-italic {font-style: italic;}
.button-underline {text-decoration: underline;}
.header-menu button svg {
    margin: auto;
    display: block;
}
.textfield_focus_false.header-menu button.menu_button, .textfield_focus_false div:not(#dropdown_more) > .header-dropdown-menu-icon, .history_button.inactive {
    color: #00000072;
}
.textfield_focus_false .color_icon {
    border-bottom-color: rgba(var(--mainColor),0.5);
}
/*.textfield_focus_true button.menu_button {
    color: black;
}*/
.textfield_focus_false .menu_button:hover::after {
    background-color: transparent;
}
button.history_button {
    font-size: 19px;
}
button.bl-icon {
    font-size: 22px;
}
/*.header-history button + button, .header-right-side button + button {
    margin-left: 10px;
}*/
/*.menu_bar_color {
    width: 22px;
    height: 22px;
    /*background-color: var(--greyBG);* /
    flex-shrink: 0;
    border-radius: 7px;
    margin: auto 3px auto 0px;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    position: relative;
}
.menu_bar_color::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border: 1px solid red;
    border-radius: 5px;
}*/
/*#dropdown_bg_color,*/ #dropdown_color {
    margin: 0;
}
.color_icon/*, .bg_color_icon*/ {
    margin: auto;
    padding: 2px 2px 0;
    border-bottom: 2px solid rgba(var(--mainColor),1);
    line-height: 1.1;
    /*font-weight: bold;*/
}
/*.color_icon {
    color: rgba(var(--mainColor),1);
}
/*.bg_color_icon {
    /*font-size: 14px;*/
    /*padding-top: 3px;
    padding-bottom: 4px;*/
    /*border: 2px solid rgb(var(--mainColor));* /
    border: 0 none;
    position: relative;
    color: black;
}
.bg_color_icon::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 20px;
    padding: 0px 3px;
    border: 3px solid rgba(var(--mainColor),0.4);
    /*background-color: rgba(var(--mainColor),1);* /
    line-height: 1.1;
    border-radius: 5px;
}*/
/*#dropdown_font_size .header-dropdown-menu-icon {
    width: auto;
}*/
#dropdown_color, #dropdown_font_size {
    display: block;
    margin: 0;
}
.ms0 {
    width: 25px !important;
}

#dropdown_font_size .dropdown_font_size_text {
    /*padding: 0px 4px 0 4px;*/
    padding-top: 4px;
    min-width: 25px;
    margin: auto;
    background-color: transparent;
    /*color: black;*/
    height: 30px;
    width: 30px;
    border-radius: 4px;
    /*font-size: 16px;*/
}
#dropdown_font_size .header-dropdown-menu-content > div {
    max-height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
}
/*#dropdown_font_size .header-dropdown-menu-content .button_text {
    margin-left: 6px;
}*/
/*#dropdown_font_size .header-dropdown-menu-content::after {
    content: '';
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 10px;
    box-shadow: var(--overlayShadow);
}*/
/*.font_size_icon::before {
    content: 'A';
    font-size: 12px;
    padding-left: 3px;
}*/

/*.font_size_input {
    border: 0 none;
    width: 27px;
    height: 27px;
    margin: auto 0 auto 6px;
    border-radius: 7px;
    background-color: transparent;
    text-align: center;
    font-size: 16px;
    /*box-shadow: 0px 0px 17px rgba(0,0,0,0.07);* /
}
.font_size_input:hover, .font_size_input:focus {
    background-color: white;
    box-shadow: 0px 0px 17px rgba(0,0,0,0.07);
}*/

.color_row {
    display: flex;
}
.color_indicator {
    width: 20px;
    height: 20px;
    border-radius: 5px;
}
.color_indicator:hover {
    outline: 2px solid var(--greyBG);
}
.color_indicator:not(:last-child) {
    margin-right: 13px;
}

#dropdown_more .button-bold, #dropdown_more .button-italic, #dropdown_more .button-underline {
    display: none;
}

/*
TEXTFIELD
*/
.textfield {
    background-color: white;
    flex: 1 0 0;
    margin-top: 0;
    margin-left: 0;
    padding: 20px;
    line-height: 1.5;
    height: calc(100vh - 190px);
}
.textfield_scroll_container {
    height: 100%;
    overflow: auto;
    padding-left: 15px;
}
.close_textfield {
    position: absolute;
    right: 20px;
    top: 24px;
    z-index: 19;
    font-size: 22px;
    display: none;
    width: 50px;
    height: 50px;
    text-align: center;
    padding-top: 14px;
}
/*.title_input_container:focus-within + .close_textfield {
    display: none;
}*/
/*.textfield p {
    margin: 0;
}*/
/*.textfield*/ ul, ol {
    margin: 0;
}

/*Link + Hover-Popup*/
.link {
    position: relative;
    color: rgb(var(--mainColor));
    text-decoration: underline;
}
.link_hover_popup {
    position: absolute;
    opacity: 0;
    white-space: nowrap;
    /*z-index: 100;*/
    background-color: white;
    box-shadow: var(--overlayShadow);
    bottom: 100%;
    left: 0;
    transition: opacity .2s, display 1s;
    transition-delay: .6s;
    padding: 4px 4px;
    display: flex;
    color: black;
    border-radius: 7px;
    z-index: -1;
}
.textfield .link:hover > .link_hover_popup {
    opacity: 1;
    z-index: 100;
}
.link_hover_popup_icon {
    padding: 4px 4px 2px;
    line-height: 1;
    border-radius: 4px;
}
.link_hover_popup_icon:hover {
    background-color: var(--greyBG);
}
.link_hover_popup_a {
    padding: 0px 4px 2px;
    color: rgb(var(--mainColor));
}
.link_hover_popup_a::after {
    content: 'Open Link';
}


.title_input_container {
    display: flex;
}
.title_input, .category_no_items_title {
    border: 0 none;
    background-color: white;
    position: relative;
    z-index: 10;
    font-size: 35px;
    font-weight: bold;
    padding: 7px 12px;
    margin-bottom: 15px;
    margin-left: -12px;
    border-radius: 10px;
    width: calc(100% + 24px);
}
.title_input:focus {
    box-shadow: var(--standardShadow);
    outline: 0 none;
}
.input_focus_bg, .dialog_bg {
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
}
.input_focus_bg {
    display: none;
}
.category_no_items_title {
    padding-top: 0;
    padding-bottom: 0;
    font-style: italic;
}

.box-svg {
    width: 60px;
    position: relative;
    margin-left: 0;
    background-color: var(--secondaryBGColor);
    font-size: 19px;
    display: block;
}
.box-svg svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.input_manage_changes {
    background-color: white;
    margin: 0 0 15px;
    margin-left: 0px;
    display: none;
    position: relative;
    z-index: 10;
    margin-left: 20px;
    color: white;
    box-shadow: rgba(255, 255, 255, 0.15) 0px 0px 20px 0px;
    font-size: 22px;
    padding-left: 20px;
    padding-right: 20px;
}
.input_confirm_changes {
    color: #76B574;
}
.input_discard_changes {
    color: #C76B6B;
}
.title_input:focus ~ .input_focus_bg, .title_input:focus ~ .input_manage_changes {display: block;} /*.title_input:focus ~ .input_focus_bg, .title_input:focus ~ .input_confirm_changes*/

.dialog {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    z-index: 12;
    width: 300px;
    padding: 20px 25px;
    margin: 0;
}
.dialog_title {
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 17px;
    position: relative;
}
.dialog_content {
    font-size: 16px;
}
.dialog_bg {
    z-index: 11;
}
form > label {
    display: block;
    margin: 0 0 10px;
}
form input, form select {
    background-color: transparent;
    border: 2px solid #ADB4C0;
    padding: 11px;
    font-size: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}
form select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
}
form .select_container {
    position: relative;
}
form .select_container::after {
    content: '\231F';
    position: absolute;
    right: 12px;
    top: 1px;
    transform: rotate(45deg);
    z-index: -1;
    font-size: 20px;
}
form > input, form select {
    width: 100%;
}
.form_fieldset {
    border: 0 none;
    text-align: right;
    padding: 0;
    margin: 10px 0 3px;
}
.form_fieldset input {
    border: 0 none;
    background-color: #8C929C;
    color: white;
    font-size: 15px;
    margin: 0 0 0 15px;
    padding: 11px 19px;
    border-radius: 8px;
    transition: background-color 0.2s;
}
.form_fieldset input:hover {
    background-color: #adb4c0b2;
}
.form_fieldset input.active {
    background-color: rgba(var(--mainColor),1);
}
.form_fieldset input.active:hover {
    background-color: rgba(var(--mainColor),0.7);
}
.login_dialog_form .form_fieldset input.active {
    background-color: rgba(89, 131, 202, 1);
}
.login_dialog_form .form_fieldset input.active:hover {
    background-color: rgba(89, 131, 202, 0.7);
}
.error_message {
    color: white;
    padding: 10px 15px 11px 57px;
    margin-top: 20px;
    margin-bottom: 22px;
    position: relative;
    background-color: #C76B6B;
    border-radius: 10px;
}
.error_message::before {
    content: '\2A09';
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    border: 1.5px solid white;
    border-radius: 50%;
    padding: 3px 7px 4px 7.5px;
}

label + .item_dialog_button_container {
    margin-top: 20px;
}

.about_container {
    display: flex;
}
.about_text {
    line-height: 1.5;
}
.about_link {
    color: rgb(var(--mainColor));
    font-weight: bold;
    text-decoration: none;
}
.about_logo {
    width: 40px;
    height: 40px;
    margin-right: 20px;
}

/*.tooltip {
    position: absolute;
    background-color: white;
    display: none;
    left: 50%;
    top: calc(100% + 7px);
    transform: translateX(-50%);
    box-shadow: var(--standardShadow);
    border-radius: 10px;
    padding: 7px 10px;
    color: black;
    white-space: nowrap;
}
/*:hover > .tooltip {
    display: block;
}* /
.tooltip.align-right {
    left: auto;
    right: 0;
    transform: none;
}*/

.image-container {
    position: relative;
}
.image-container-align-center {
    margin: auto;
}
.image-container-align-right {
    margin-left: auto;
}
.delete-image-button, .resizer {
    position: absolute;
    right: 0;
    background-color: white;
    font-size: 19px;
    border-radius: 7px;
    display: none;
    padding: 5px 5px 1px;
}
.delete-image-button {
    top: 0;
    border: 0 none;
}
.selected .delete-image-button, .selected .resizer {display: block;}
.resizer {
    bottom: 0;
}

.settings_title {
    position: relative;
    font-weight: bold;
}
.close_button {
    position: absolute;
    right: -7.5px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: normal;
    font-size: 19px;
    padding: 8px 8px 3px;
    border-radius: 7px;
}
.close_button:hover {background-color: var(--greyBG)}

/*
SETTINGS
*/
/*.settings {
    margin-top: var(--headerHeight);
    margin-left: 20px;
    font-size: 16px;
}*/
.settings_title {
    /*margin-top: 8px;*/
    font-weight: bold;
    font-size: 22px;
}


fieldset {
    border: 0 none;
    padding: 0;
    margin: 0;
}
.category_list {
    margin-bottom: 0;
}
.settings_dialog_form fieldset {
    text-align: left;
    position: relative;
}
.settings_dialog_form fieldset:not(:last-of-type) {
    margin-bottom: 20px;
}
.settings_dialog_form fieldset:first-of-type {
    margin-top: 25px;
}
legend {
    padding-left: 0;
    margin-bottom: 10px;
}
.category_list legend {
    margin-bottom: 7px;
}
.settings_dialog_form input[type='radio'] {
    margin-bottom: 15px;
    opacity: 0;
}
.settings_dialog_form input[type='radio'] + label, .check_container {
    position: relative;
}
.settings_dialog_form input[type='radio'] + label {
    margin-left: 5px;
}
.check_container {
    padding-left: 30px;
}
.settings_dialog_form .check_container + .check_container {
    margin-top: 10px;
}
.settings_dialog_form input[type='radio'] + label::before, input[type='checkbox'] + label::before, .checkbox {
    content: '';
    position: absolute;
    /*width: 17px;
    height: 17px;*/
    left: -31px;
    top: 1px;
    /*transform: translateY(-50%);*/
    /*background-color: var(--greyBG);*/
    border-radius: 5px;
    /*border: 2px solid rgba(var(--mainColor), 0.2);*/
    background-color: rgba(0, 0, 0, 0.2);
    background-color: rgba(var(--mainColor), 0.2);
}
.checkbox {
    left: 0;
    width: 21px;
    height: 21px;
}
.settings_dialog_form input[type='radio']:checked + label::before, .checked_true .checkbox {
    border: 2px solid rgb(var(--mainColor));
    background-color: rgb(var(--mainColor));
}
.settings_dialog_form input[type='radio']:checked + label::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    left: -31px;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 6.5px;
    background-color: white;
    border-radius: 2px;
}

.login_dialog_check {
    position: relative;
    margin-top: 3px;
}
.login_dialog_check label {
    margin-left: 10px;
    position: relative;
}
.checkmark {
    position: absolute;
    top: 0px;
    left: 0px;
    /*top: 2px;
    left: 3px;*/
    color: white;
    z-index: -1;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.check_container .checkmark {
    z-index: 1;
}
.checked_false .checkmark {
    opacity: 0;
}

/*input[type="checkbox"] {
    margin-bottom: 7px;
    opacity: 0;
}
input[type='checkbox']:checked + label::before {
    border: 2px solid rgba(89, 131, 202, 1);
    background-color: rgba(89, 131, 202, 1);
    z-index: -2;
}*/

.settings_dialog_form legend {font-weight: bold;}
.settings_dialog_form input[type='button'] {
    margin-top: 13px;
    margin-left: 0;
    padding: 9px 13px;
}

.settings_dialog_form .settings_category {
    padding: 6px 0px;
    position: relative;
}
.settings_dialog_form .settings_category .settings_category_tools {
    display: flex;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 17px;
    color: transparent;
    transition: .2s color;
}
/*.settings_category:hover .settings_category_tools {
    display: flex;
}*/
.settings_category {
    display: flex;
    align-items: center;
}
.settings_category:not(.settings_category_nocategory):hover .settings_category_tools {
    color: #4f4f4f;
}
.settings_category .settings_category_tools > div {
    padding: 7px 7px 3px;
    border-radius: 7px;
}
/*.settings_category:not(.settings_category_nocategory) .settings_category_tools > div:hover {
    background-color: var(--greyBG);
}*/
input.color_picker {
    padding: 0;
    border: 0;
    margin-left: 0;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    background-color: transparent;
}
::-webkit-color-swatch, ::-moz-color-swatch {
    border: 0 none;
    border-radius: 6px;
}
::-webkit-color-swatch-wrapper {
    padding: 0;
}
.settings_category_nocategory .settings_category_title {font-style: italic; color: #4f4f4f;}
.main_color_picker {
    margin-bottom: 0;
}
.add-category {
    position: absolute;
    top: -32px;
    right: 0;
    border: 0 none;
    background-color: transparent !important;
    color: black !important;
    padding: 7px 7px 3px;
    border-radius: 6px;
}
.add-category:hover {
    background-color: var(--greyBG) !important;
}

/*
BUTTON
*/
.button {
    display: flex;
    padding: 16px;
    align-items: center;
    background-color: white;
    box-shadow: var(--standardShadow);
    width: max-content;
    border-radius: var(--standardBorderRadius);
    cursor: default;
}
/*.button:hover {
    background-color: rgba(255, 255, 255, 0.7);
}*/
.button_icon {
    line-height: 1;
    padding-right: 12px;
    font-size: 17px;
    display: flex;
    align-items: center;
}
.button.no_bg {
    background-color: transparent;
    box-shadow: none;
}
.button.p0 {
    padding: 0;
}
.button_description + .button {
    margin-top: 25px;
}



.category_no_items_content {
    margin: 20px;
    width: 100%;
}
.category_no_items_text {
    line-height: 1.5;
}

/*
BANNER
*/

.banner {
    position: fixed;
    bottom: -300px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgb(var(--mainColor));
    padding: 20px;
    border-radius: var(--standardBorderRadius);
    color: white;
    display: flex;
    transition: .7s bottom ease-in-out;
}
.banner_title {
    font-weight: bold;
    margin-bottom: 5px;
}
.banner_close_button {
    margin: auto;
    line-height: .7;
    padding: 14px;
    border-radius: var(--standardBorderRadius);
    margin-left: 20px;
}
.banner_close_button:hover {
    background-color: rgba(255,255,255,0.5);
}
.banner_link {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.demo_saving_banner_active .demo_saving_banner {
    bottom: 20px;
}
